import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Title = styled.h2`
  font-size: 13rem;
  font-weight: 700;
  line-height: 12rem;
  color: ${({theme}) => theme.colors.black};
  text-align: left;
  text-transform: lowercase;
  max-width: 50%;
  margin-bottom: 4.8rem;
`;

const SectionTitle = ({children}) => (
    <Title>
        {children}
    </Title>
);

SectionTitle.propTypes = {
    children: PropTypes.element.isRequired
};

export default SectionTitle;