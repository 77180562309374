import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../layouts/Layout';
import Section from '../components/shared/wraps/Section/Section';
import SectionTitle from '../components/shared/text/SectionTitle';
import ParagraphShort from '../components/shared/text/ParagraphShort';

const StyledSection = styled(Section)`
  margin-bottom: 10rem;
`;

const WhatWeDo = ({ location: { pathname: path } }) => (
  <Layout path={path} id="co-robimy" nonBgc>
    <SEO title="Co robimy?" />
    <StyledSection nonGrid site first>
      <SectionTitle>Strony internetowe</SectionTitle>
      <ParagraphShort>
        Nam et felis id justo ullamcorper varius phasellus sem quam efficitur
        molestie urna vel, porta blandit elit. Integer sollicitudin odio ex in
        vestibulum tortor ultrices vel nam id mauris erat get.
      </ParagraphShort>
      <ParagraphShort>
        Maecenas nunc lacus, ullamcorper ac faucibus non, posuere cursus massa
        aenean viverra sapien nec volutpat fringilla praesent faucibus eleifend
        condimentum. Integer ac est leo.
      </ParagraphShort>
      <ParagraphShort>
        Praesent sed lacus sed mauris accumsan sagittis eget quis sapien. unc
        quis viverra diam vitae tempus libero ann.
      </ParagraphShort>
    </StyledSection>
  </Layout>
);

export default WhatWeDo;
